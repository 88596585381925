






















import {
  defineComponent, onMounted, useRouter, ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TrustmateHome',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup({ blok }: { blok: any }) {
    const router = useRouter();
    const trustmateId = ref(blok.id);
    const loadScriptOnLoad = () => {
      if (router.currentRoute.query.no_script) return;
      const temp = document.createElement('script');
      temp.setAttribute('src', `https://trustmate.io/api/widget/${trustmateId.value}/script`);
      document.head.append(temp);
    };
    onMounted(() => {
      loadScriptOnLoad();
    });
    return {
      trustmateId,
    };
  },
});
